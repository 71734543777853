<template>
  <div>
    <Header titleurl="jobwanted"></Header>
    <div class="box">
      <div class="center">
        <div class="titleName">
          <span>您正在创建简历...</span>
          <div class="hess" style="color：#0000ee;" @click="getOut">退出</div>
        </div>
      </div>
      <div class="center">
        <div class="forms">
          <!-- form表单 -->
          <el-form :model="form" :rules="rules" ref="forms" label-width="100px">
            <!-- 公共样式 -->
            <div class="commit">
              <div class="commit_icon">
                <span>
                  <i class="el-icon-edit"></i>
                </span>
                <span>求职信息</span>
              </div>
              <div class="separator"></div>
            </div>
            <div class="commitBox">
              <!-- 职位类别 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="10">
                  <el-form-item class="formL" label="职位类别" prop="expectJobs">
                    <el-cascader v-model="form.expectJobs" :options="expectJobsOpt" style="width: 300px"
                      @change="getCascader"></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 工作地点 -->
              <el-row :gutter="5" style="marginleft: 240px">
                <el-col :span="18">
                  <el-form-item class="forma" label="工作地点" prop="workAddress">
                    <!-- 省 -->
                    <el-col :span="6">
                      <el-select v-model="form.workAddress.provinces" placeholder="请选择" style="width: 160px"
                        @change="getProvinces1">
                        <el-option v-for="(item, index) in province1" :key="index" :label="item.itemText"
                          :value="item.itemValue"></el-option>
                      </el-select>
                    </el-col>
                    <!-- 市 -->
                    <el-col :span="6">
                      <el-select v-model="form.workAddress.citys" placeholder="请选择"
                        style="width: 160px; marginleft: 30px" @change="getCitys1">
                        <el-option v-for="(item, index) in city1" :key="index" :label="item.itemText"
                          :value="item.itemValue"></el-option>
                      </el-select>
                    </el-col>
                    <!-- 区、县、镇 -->
                    <el-col :span="6">
                      <el-select v-model="form.workAddress.towns" placeholder="请选择"
                        style="width: 160px; marginleft: 60px" @change="getTowns1">
                        <el-option v-for="(item, index) in town1" :key="index" :label="item.itemText"
                          :value="item.itemValue"></el-option>
                      </el-select>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 期望薪资 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="10">
                  <el-form-item class="formL" label="期望薪资" prop="expectSalary">
                    <el-select v-model="form.expectSalary" placeholder="请选择" style="width: 300px"
                      @change="getexpectSalary">
                      <el-option v-for="(item, index) in expectSalaryList" :key="index" :label="item.itemText"
                        :value="item.itemValue"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <!-- 公共样式 -->
            <div class="commit">
              <div class="commit_icon">
                <span>
                  <i class="el-icon-edit"></i>
                </span>
                <span>基本信息</span>
              </div>
              <div class="separator"></div>
            </div>
            <div class="commitBox">
              <!-- 头像 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="10">
                  <el-form-item class="formL" label="头像" prop="avatarId">
                    <el-upload :action="uploadUrl" list-type="picture-card" name="file" :headers="myHeaders"
                      :show-file-list="false" :on-success="handleAvatarSuccess" :on-progress="beforeAvatarUpload">
                      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                      <img v-else src="../../assets/img/timgs.jpg" class="avatar" />
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 姓名 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="10">
                  <el-form-item class="formL" label="姓名" prop="username" style="width: 400px">
                    <el-input v-model="form.username"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 性别 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="10">
                  <el-form-item class="formL" label="性别" prop="sex">
                    <el-select v-model="form.sex" placeholder="请选择" style="width: 300px" @change="getSex">
                      <el-option v-for="item in sexList" :key="item.value" :label="item.itemText"
                        :value="item.itemValue"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 出生年份 -->
              <el-row :gutter="0" class="time" style="marginleft: 230px">
                <el-col :span="15">
                  <el-form-item label="年龄" required style="marginleft: 25px" prop="age">
                    <el-input v-model="form.age" style="width: 300px" />
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 工作年限 -->
              <el-row :gutter="0" class="time" style="marginleft: 230px">
                <el-col :span="15">
                  <el-form-item label="工作年限" required style="marginleft: 25px" prop="workExp">
                    <el-col :span="10">
                      <el-select v-model="form.workExp" placeholder="请选择" style="width: 300px" @change="getworkExp">
                        <el-option v-for="(item, index) in workExpOptions" :key="index" :label="item.label"
                          :value="item.id"></el-option>
                      </el-select>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 最高学历 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="10">
                  <el-form-item class="formL" label="最高学历" prop="education">
                    <el-select v-model="form.education" placeholder="请选择" style="width: 300px" @change="getEducation">
                      <el-option v-for="item in educations" :key="item.value" :label="item.itemText"
                        :value="item.itemValue"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 手机号码 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="8">
                  <el-form-item class="formL" label="手机号码" prop="phone">
                    <el-input v-model="form.phone" style="width: 300px"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="btn">
                  <el-button type="primary" id="btns" @click="set()">获取验证码</el-button>
                  <el-button type="primary" id="btnz">{{ time }}</el-button>
                </el-col>
              </el-row>
              <!-- 验证码 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="8">
                  <el-form-item class="formL" label="验证码" prop="phoneCode">
                    <el-input v-model="form.phoneCode" style="width: 100px"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 籍贯 -->
              <el-row :gutter="10" style="marginleft: 234px">
                <el-col :span="18">
                  <el-form-item class="forma" label="籍贯" prop="nativePlace">
                    <!-- 省 -->
                    <el-col :span="6">
                      <el-select v-model="form.nativePlace.provinces" placeholder="请选择" style="width: 160px"
                        @change="getProvinces2">
                        <el-option v-for="(item, index) in province2" :key="index" :label="item.itemText"
                          :value="item.itemValue"></el-option>
                      </el-select>
                    </el-col>
                    <!-- 市 -->
                    <el-col :span="6">
                      <el-select v-model="form.nativePlace.citys" placeholder="请选择"
                        style="width: 160px; marginleft: 30px" @change="getCitys2">
                        <el-option v-for="(item, index) in city2" :key="index" :label="item.itemText"
                          :value="item.itemValue"></el-option>
                      </el-select>
                    </el-col>
                    <!-- 区、县、镇 -->
                    <el-col :span="6">
                      <el-select v-model="form.nativePlace.towns" placeholder="请选择"
                        style="width: 160px; marginleft: 60px" @change="getTowns2">
                        <el-option v-for="(item, index) in town2" :key="index" :label="item.itemText"
                          :value="item.itemValue"></el-option>
                      </el-select>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 现居住地 -->
              <el-row :gutter="10" style="marginleft: 234px">
                <el-col :span="18">
                  <el-form-item class="forma" label="现居住地" prop="address">
                    <!-- 省 -->
                    <el-col :span="6">
                      <el-select v-model="form.address.provinces" placeholder="请选择" style="width: 160px"
                        @change="getProvinces3">
                        <el-option v-for="(item, index) in province3" :key="index" :label="item.itemText"
                          :value="item.itemValue"></el-option>
                      </el-select>
                    </el-col>
                    <!-- 市 -->
                    <el-col :span="6">
                      <el-select v-model="form.address.citys" placeholder="请选择" style="width: 160px; marginleft: 30px"
                        @change="getCitys3">
                        <el-option v-for="(item, index) in city3" :key="index" :label="item.itemText"
                          :value="item.itemValue"></el-option>
                      </el-select>
                    </el-col>
                    <!-- 区、县、镇 -->
                    <el-col :span="6">
                      <el-select v-model="form.address.towns" placeholder="请选择" style="width: 160px; marginleft: 60px"
                        @change="getTowns3">
                        <el-option v-for="(item, index) in town3" :key="index" :label="item.itemText"
                          :value="item.itemValue"></el-option>
                      </el-select>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 自我介绍 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="10">
                  <el-form-item class="formL" label="自我介绍" prop="selfAssessment">
                    <el-input type="textarea" v-model="form.selfAssessment" size="medium"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <!-- 公共样式 -->
            <div class="commit">
              <div class="commit_icon">
                <span>
                  <i class="el-icon-edit"></i>
                </span>
                <span>附件简历</span>
              </div>
              <div class="separator"></div>
            </div>
            <div class="commitBox">
              <!-- 上传文件 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-form-item class="formL" label prop="resume">
                  <el-col :span="11">
                    <el-upload class="upload-demo" drag :action="uploadResume" :headers="myHeaders"
                      :data="uploadResumeData" name="file" :limit="1" :on-success="resumeSuccess"
                      :on-error="resumeError">
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        将文件拖到此处，或
                        <em>点击上传</em>
                      </div>
                    </el-upload>
                  </el-col>
                </el-form-item>
              </el-row>
            </div>
            <!-- 公共样式 -->
            <!-- <div class="commit">
              <div class="commit_icon">
                <span>
                  <i class="el-icon-edit"></i>
                </span>
                <span>加分信息</span>
              </div>
              <div class="separator"></div>
            </div> -->
            <!-- 上传加分附件信息 -->
            <!-- <el-row :gutter="20" style="marginLeft:230px;">
              <el-form-item class="formL" label prop="attachmentFiles">
                <el-col :span="11">
                  <el-upload
                    class="upload-demo"
                    drag
                    action="/store/upload"
                    name="files"
                    :limit=1
                    :on-success="attachmentFilesSuccess"
                    :on-error="attachmentFilesError"
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                    </div>
                  </el-upload>
                </el-col>
              </el-form-item>
            </el-row> -->
            <!-- 公共样式 -->
            <div class="commit">
              <div class="commit_icon">
                <span>
                  <i class="el-icon-edit"></i>
                </span>
                <span>隐私设置</span>
              </div>
              <div class="separator"></div>
            </div>
            <div class="commitBox">
              <!-- 选择权限 -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="10">
                  <el-form-item class="formL" label="谁能看到这份简历" prop="privacySetting" label-width="200px">
                    <el-radio v-model="form.privacySetting" label="0" class="choice">
                      <span class="choice_title">所有招聘方</span>
                      <span class="choice_class">展示简历给通过营业执照或法人等认证的招聘方</span>
                    </el-radio>
                    <el-radio v-model="form.privacySetting" label="1" class="choice">
                      <span class="choice_title">仅我投递的招聘方</span>
                      <span class="choice_class">不会在人才库显示您的简历，不会被非投递的招聘方主动邀约</span>
                    </el-radio>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- btn -->
              <el-row :gutter="20" style="marginleft: 230px">
                <el-col :span="10">
                  <el-button type="primary" class="button" @click="postResume">保存文档</el-button>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../../components/header';
import Footer from '../../components/footer';
import {
  postResumes,
  ExpectJobs,
  phone,
} from '@/api/resume';
import { candidatefilter } from '@/api/jobwant';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');

export default {
  name: 'resume',
  components: {
    Header,
    Footer,
  },
  data: function () {
    return {
      // entityId: '5418',
      form: {
        category: '',
        // 姓名
        username: '',
        // 性别
        sex: '',
        // 性别编码
        sexCode: '',
        // 年龄
        age: '',
        // date1: '',
        desc: '',
        // 电话
        phone: '',
        // 自我介绍
        selfAssessment: '',
        // 职业类别
        expectJobs: '',
        // 隐藏设置
        privacySetting: '0',
        // 工作地点
        workAddress: {
          // 省
          provinces: '',
          provinceName: '',
          // 市
          citys: '',
          citysName: '',
          // 镇
          towns: '',
          townName: '',
        },
        // 籍贯
        nativePlace: {
          // 省
          provinces: '',
          provinceName: '',
          provinceCode: '',
          // 市
          citys: '',
          citysName: '',
          cityCode: '',
          // 镇
          towns: '',
          townName: '',
          countyCode: '',
        },
        // 居住地
        address: {
          // 省
          provinces: '',
          provinceName: '',
          provinceCode: '',
          // 市
          citys: '',
          citysName: '',
          cityCode: '',
          // 镇
          towns: '',
          townName: '',
          countyCode: '',
        },
        // 工作年限
        workExp: '',
        // 期望薪资
        expectSalary: '',
        expectSalaryCode: '',
        // 验证码
        phoneCode: '',
        // 学历
        education: '',
        educationCode: '',
        //城市编码
        cityCode: '',
        //期望城市
        workCity: '',
      },
      rules: {
        expectSalary: [
          { required: true, message: '请选择活动区域', trigger: 'change' },
        ],
        username: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        selfAssessment: [
          { required: true, message: '请选择活动区域', trigger: 'change' },
        ],
        phone: [
          { required: true, message: '不能为空', trigger: 'blur' },
          { pattern: /^\d{11}$/, message: '格式错误，仅支持11位数字' },
        ],
        expectJobs: [{ required: true, message: '不能为空', trigger: 'blur' }],
        age: [{ required: true, message: '请输入年龄', trigger: 'blur' }],
        // avatarId:[{ required: true, message: '请选择上传头像', trigger: 'blur' }],
        // resume:[{ required: true, message: '请选择上传简历', trigger: 'blur' }]
      },
      expectJobsOpt: [],
      // 学历
      educations: [],
      // 图片上传
      imageUrl: '',
      // 验证码时间
      time: 60,
      times: '',
      // 省
      province1: [],
      // 省
      province2: [],
      // 省
      province3: [],
      // 市
      city1: [],
      // 市
      city2: [],
      // 市
      city3: [],
      // 市
      citys1: '',
      // 市
      citys2: '',
      // 市
      citys3: '',
      // 镇
      town1: [],
      // 镇
      town2: [],
      // 镇
      town3: [],
      // 公用的地点名称
      place: '',
      // 用户id
      // uid: 230,
      // 工作年限
      workExpOptions: [
        {
          id: 1,
          label: 1,
        },
        {
          id: 2,
          label: 2,
        },
        {
          id: 3,
          label: 3,
        },
        {
          id: 4,
          label: 4,
        },
        {
          id: 5,
          label: 5,
        },
        {
          id: 6,
          label: 6,
        },
        {
          id: 7,
          label: 7,
        },
        {
          id: 8,
          label: 8,
        },
        {
          id: 9,
          label: 9,
        },
        {
          id: 10,
          label: 10,
        },
      ],
      // 期望薪资的数据
      expectSalaryList: [],
      // 性别
      sexList: [],
      // 添加求职的参数
      bean: {
        age: 0,
        workAddress: '',
        sex: '',
        sexCode: '',
        username: '',
        selfAssessment: '',
        expectSalary: '',
        workExp: '',
        education: '',
        nativePlace: '',
        address: '',
        expectJobs: '',
        phoneCode: '',
        privacySetting: 0,
        phone: '',
        avatarId: '',
        attachmentFiles: [{ key: 0,value: ''  }],
        // attachmentFiles:''
        expectSalaryCode: '',
        educationCode: '',
        workCity: '',
        cityCode: '',
        // 居住地编码
        dwellGisCoord: {
          provinceCode: '',
          province: '',
          cityCode: '',
          city: '',
          countyCode: '',
          county: '',
        },
        // 籍贯编码
        nativeGisCoord: {
          provinceCode: '',
          province: '',
          cityCode: '',
          city: '',
          countyCode: '',
          county: '',
        },
        //简历完整度
        candidateIntegrity: 0,
      },
      // 头像参数
      avatarId: '',
      // 上传简历的参数
      attachmentFiles: [{ key: 0,value: ''  }],
      // // 上传加分附件信息
      // attachmentFiles:''
      // 上传的请求头
      myHeaders: { Authorization: 'Bearer ' + token },
      //UploadeUrl地址
      uploadUrl: this.$uplodeUrl + 'store/upload/single',
      // 工作地点拼接名
      workAddressP: '',
      // 籍贯拼接名
      nativePlaceP: '',
      // 居住地拼接名
      addressP: '',
      // 简历地址
      uploadResume: this.$uplodeUrl + 'store/attachment/upload/single',
      uploadResumeData: {
        fileType: 'candidate',
        fileTypeCode: '简历',
      },
      // 进度条数据
      percentage: 60,
    };
  },
  mounted() {
    document.getElementById('btnz').style.display = 'none';
    this.getProvince();
    this.getExpectJobs();
    this.getCandidatefilter();
  },
  methods: {
    //退出
    getOut() {
      window.history.go(-1);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 上传头像前的钩子函数
    beforeAvatarUpload(file) {
      console.log(file);
    },
    // 上传头像成功的钩子函数
    handleAvatarSuccess(response, file) {
      this.imageUrl = file.url;
      console.log(response);
      console.log(file);
      this.form.avatarId = response.result;
    },
    // 验证码
    async set() {
      const reg = /^1[0-9]\d{9}$/;
      if (!reg.test(this.form.phone)) {
        document.getElementById('btnz').style.display = 'none';
      } else {
        document.getElementById('btns').style.display = 'none';
        // document.getElementById("btns").disabled=ture;
        document.getElementById('btnz').style.display = 'block';
        this.times = setInterval(this.depTime, 1000);
        const res = await phone({ phone: this.form.phone });
        console.log(res);
        if (res.data.success == true) {
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      }
    },
    depTime() {
      if (this.time > 0) {
        this.time--;
        document.getElementById('btnz').style.disabled = true;
      } else {
        clearInterval(this.times);
        document.getElementById('btns').style.display = 'block';
        document.getElementById('btnz').style.display = 'none';
        this.time = 60;
      }
    },
    // // 省
    // async getProvince() {
    //   // let uid = this.uid;
    //   const { data: res } = await getProvincem();
    //   // console.log(res);
    //   if (res.code == 200) {
    //     this.province1 = res.result.rows;
    //     this.province2 = res.result.rows;
    //     this.province3 = res.result.rows;
    //   } else {
    //     return;
    //   }
    // },
    // // 工作地点
    // getProvinces1(e) {
    //   this.city1 = [];
    //   this.form.workAddress.citys = '';
    //   this.town1 = [];
    //   this.form.workAddress.towns = '';
    //   let params = {
    //     provinceCode: e,
    //     // uid: this.uid,
    //   };
    //   this.getCity(e, params).then((res) => {
    //     this.city1 = res;
    //     this.getLoop(e, this.province1);
    //     this.form.workAddress.provinceName = this.place;
    //   });
    // },
    // // 籍贯
    // getProvinces2(e) {
    //   this.form.nativePlace.provinceCode = e;
    //   this.city2 = [];
    //   this.form.nativePlace.citys = '';
    //   this.town2 = [];
    //   this.form.nativePlace.towns = '';
    //   let params = {
    //     provinceCode: e,
    //     // uid: this.uid,
    //   };
    //   this.getCity(e, params).then((res) => {
    //     this.city2 = res;
    //     this.getLoop(e, this.province2);
    //     this.form.nativePlace.provinceName = this.place;
    //   });
    // },
    // // 居住地
    // getProvinces3(e) {
    //   this.form.address.provinceCode = e;
    //   console.log(this.form.address.provinceCode);
    //   this.city3 = [];
    //   this.form.address.citys = '';
    //   this.town3 = [];
    //   this.form.address.towns = '';
    //   let params = {
    //     provinceCode: e,
    //     // uid: this.uid,
    //   };
    //   this.getCity(e, params).then((res) => {
    //     this.city3 = res;
    //     this.getLoop(e, this.province3);
    //     this.form.address.provinceName = this.place;
    //     // console.log(this.form.address.provinceName);
    //   });
    // },
    // // 市
    // async getCity(code, params) {
    //   // console.log(code);
    //   // console.log(params);
    //   const { data: res } = await getCitym(code, params);
    //   if (res.code == 200) {
    //     return res.result.rows;
    //   } else {
    //     // return;
    //   }
    // },
    // // 工作地点
    // getCitys1(e) {
    //   this.citys1 = e;
    //   this.town1 = [];
    //   this.form.workAddress.towns = '';
    //   let params = {
    //     cityCode: e,
    //     // uid: this.uid,
    //   };
    //   // console.log(this.city1)
    //   this.getTown(e, params).then((res) => {
    //     this.town1 = res;
    //     this.getLoop(e, this.city1);
    //     this.form.workAddress.citysName = this.place;
    //     this.form.workCity = this.place;
    //     this.form.cityCode = e;
    //   });
    // },
    // // 籍贯
    // getCitys2(e) {
    //   this.form.nativePlace.cityCode = e;
    //   this.citys2 = e;
    //   this.town2 = [];
    //   this.form.nativePlace.towns = '';
    //   let params = {
    //     cityCode: e,
    //     // uid: this.uid,
    //   };
    //   this.getTown(e, params).then((res) => {
    //     this.town2 = res;
    //     this.getLoop(e, this.city2);
    //     this.form.nativePlace.citysName = this.place;
    //     // console.log(this.form.nativePlace.citysName);
    //   });
    // },
    // // 居住地
    // getCitys3(e) {
    //   this.form.address.cityCode = e;
    //   this.citys3 = e;
    //   this.town3 = [];
    //   this.form.address.towns = '';
    //   let params = {
    //     cityCode: e,
    //     // uid: this.uid,
    //   };
    //   this.getTown(e, params).then((res) => {
    //     this.town3 = res;
    //     this.getLoop(e, this.city3);
    //     this.form.address.citysName = this.place;
    //     // console.log(this.form.address.citysName);
    //   });
    // },
    // // 镇
    // async getTown(code, params) {
    //   const { data: res } = await getTownm(code, params);
    //   // console.log(res);
    //   if (res.code == 200) {
    //     return res.result.rows;
    //   } else {
    //     // return;
    //   }
    // },
    // // 工作地点
    // getTowns1(e) {
    //   this.getLoop(e, this.town1);
    //   this.form.workAddress.townName = this.place;
    // },
    // // 籍贯
    // getTowns2(e) {
    //   this.form.nativePlace.countyCode = e;
    //   this.getLoop(e, this.town2);
    //   this.form.nativePlace.townName = this.place;
    //   // console.log(this.form.nativePlace.townName);
    // },
    // // 居住地
    // getTowns3(e) {
    //   this.form.address.countyCode = e;
    //   this.getLoop(e, this.town3);
    //   this.form.address.townName = this.place;
    //   // console.log(this.form.address.townName);
    // },
    // 循环地点名称事件
    getLoop(code, arr) {
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item.itemValue == code) {
            this.place = item.itemText;
          } else if (item.id == code) {
            this.place = item.label;
          } else if (item.value == code) {
            this.place = item.label;
          } else {
            return;
          }
        });
      }
    },
    // 头像上传时的钩子函数
    avatarIdPreview(file) {
      this.file = file;
    },
    // 期望薪资事件
    getexpectSalary(e) {
      // console.log(e)
      this.getLoop(e, this.expectSalaryList);
      // console.log(this.place);
      this.form.expectSalary = this.place;
      this.form.expectSalaryCode = e;
    },
    // 工作年限事件
    getworkExp(e) {
      this.getLoop(e, this.workExpOptions);
      // console.log(this.place);
      this.form.workExp = this.place;
    },
    // 学历
    getEducation(e) {
      this.getLoop(e, this.educations);
      // console.log(this.place);
      // console.log(e);
      this.form.education = this.place;
      this.form.educationCode = e;
    },
    // 性别
    getSex(e) {
      this.getLoop(e, this.sexList);
      this.form.sex = this.place;
      this.form.sexCode = e;
      // console.log(this.place)
      // console.log(e)
    },
    // 职业类别
    async getExpectJobs() {
      const { data: res } = await ExpectJobs();
      console.log(res.result);
      //  this.expectJobsOpt = res.result
      let Jobs = res.result;
      let newarr = [];
      this.handleData(Jobs, newarr);
      console.log(newarr);
      this.expectJobsOpt = newarr;
      console.log(this.expectJobsOpt);
    },
    // 级联选择器循环事件
    handleData(data, newarr) {
      const len = data.length;
      for (let i = 0; i < len; i++) {
        //如果子级存在,并且有数据
        if (data[i].children && data[i].children.length > 0) {
          //label为级联选择器要展示的值,我这里使用的是name,value为选中的值,我这里使用的是id,根据个人使用场景,按需调试
          newarr.push({ value: data[i].id, label: data[i].name, children: [] });
          this.handleData(data[i].children, newarr[i].children);
        } else {
          //子级不存在时,不用push  children:[],否则级联选择器最后一页会出现空白,很难看
          newarr.push({ value: data[i].id, label: data[i].name });
        }
      }
      return newarr;
    },
    // 职业类别级联选择器事件
    getCascader(e) {
      for (let i = 0; i < e.length; i++) {
        this.form.expectJobs = e[e.length - 1];
      }
    },
    // 上传简历成功时的钩子
    resumeSuccess(response, file, fileList) {
      console.log(response);

      this.attachmentFiles[0].key = response.result.attachmentId;
      this.attachmentFiles[0].value = response.result.appendixId;
      console.log(this.attachmentFiles);
    },
    // 上传简历失败的钩子
    resumeError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传简历失败');
    },
    // 删除上传文件列表事件
    // resumeRemove(file, fileList){
    //  console.log(file)
    //  console.log(fileList)
    // },
    //  // 上传加分附件信息成功时的钩子
    // attachmentFilesSuccess(response, file, fileList){
    //   this.resume = response.result
    //   console.log(response)
    //   console.log(file)
    //   console.log(fileList)
    // },
    // // 上传加分附件信息失败的钩子
    // attachmentFilesError(err, file, fileList){
    //   fileList = []
    //   return this.$message.error('上传简历失败')
    // },
    // 求职过滤条件
    async getCandidatefilter() {
      const { data: res } = await candidatefilter();
      if (res.code == 200) {
        let expectSalaryList = res.result.expectSalarys;
        this.expectSalaryList = expectSalaryList.slice(
          1,
          expectSalaryList.length - 1
        );
        let educations = res.result.educations;
        this.educations = educations.slice(1, educations.length - 1);
        this.sexList = res.result.sexs;
        //  let workExpOptions = res.result.workYears
        //  this.workExpOptions = workExpOptions.slice(1,workExpOptions.length-1)
      } else {
        this.$message.error('过滤条件获取失败！');
      }
      //  console.log(res)
    },
    // 保存简历事件
    postResume() {
      // 工作地点拼接
      this.form.workAddressP =
        this.form.workAddress.provinceName + this.form.workAddress.citysName;
      // 籍贯
      this.form.nativePlaceP =
        this.form.nativePlace.provinceName +
        this.form.nativePlace.citysName +
        this.form.nativePlace.townName;
      // 居住地
      this.form.addressP =
        this.form.address.provinceName +
        this.form.address.citysName +
        this.form.address.townName;

      // 表单获取到的数据
      this.bean.workAddress = this.form.workAddressP;
      this.bean.age = parseInt(this.form.age);
      this.bean.sex = this.form.sex;
      this.bean.sexCode = this.form.sexCode;

      this.bean.username = this.form.username;
      this.bean.selfAssessment = this.form.selfAssessment;
      this.bean.expectSalary = this.form.expectSalary;
      this.bean.expectSalaryCode = this.form.expectSalaryCode;
      this.bean.candidateIntegrity = this.percentage;

      this.bean.workExp = this.form.workExp;
      this.bean.education = this.form.education;
      this.bean.educationCode = this.form.educationCode;
      this.bean.nativePlace = this.form.nativePlaceP;
      this.bean.address = this.form.addressP;
      this.bean.expectJobs = this.form.expectJobs;
      this.bean.phone = this.form.phone;
      this.bean.phoneCode = parseInt(this.form.phoneCode);
      this.bean.privacySetting = parseInt(this.form.privacySetting);
      this.bean.avatarId = this.form.avatarId;
      this.bean.attachmentFiles = this.attachmentFiles;
      this.bean.cityCode = this.form.cityCode;
      this.bean.workCity = this.form.workCity;
      this.bean.dwellGisCoord.provinceCode = this.form.address.provinceCode;
      this.bean.dwellGisCoord.province = this.form.address.provinceName;
      this.bean.dwellGisCoord.cityCode = this.form.address.cityCode;
      this.bean.dwellGisCoord.city = this.form.address.citysName;
      this.bean.dwellGisCoord.county = this.form.address.townName;
      this.bean.dwellGisCoord.countyCode = this.form.address.countyCode;

      this.bean.nativeGisCoord.provinceCode = this.form.nativePlace.provinceCode;
      this.bean.nativeGisCoord.province = this.form.nativePlace.provinceName;
      this.bean.nativeGisCoord.cityCode = this.form.nativePlace.cityCode;
      this.bean.nativeGisCoord.city = this.form.nativePlace.citysName;
      this.bean.nativeGisCoord.countyCode = this.form.nativePlace.countyCode;
      this.bean.nativeGisCoord.county = this.form.nativePlace.townName;
      console.log(this.bean.nativeGisCoord);

      this.$refs.forms.validate(async (valid) => {
        if (!valid) {
          this;
          return this.$message.error('表单错误请重新添加表单');
        } else {
          let bean = this.bean;
          const { data: res } = await postResumes(bean);
          console.log(res);
          if (res.code == 200) {
            this.$message.success('保存成功！');
            clearInterval(this.times);
            this.$router.push('/jobwanted');
          } else {
            this.getProvince();
          }
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="../../assets/style/recruit/jobresumed.css" scope>
</style>
